import React from 'react';
import { useAuth } from '../AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import asknaturelogo from '../static/images/asknaturelogo.png';
import logoImage from '../static/images/uzh.svg';

const Home = () => {
    const { auth } = useAuth(); // Get authentication state

    return (
        <div>
            {/* Navigation */}
            <nav className="navbar navbar-expand-lg navbar-dark bg-transparent fixed-top">
    <div className="container-lg my-3 menucontainer">
        <a className="navbar-brand" href="/">
            <img
                src={asknaturelogo}
                alt="AskNature Logo"
                style={{ width: '150px', height: 'auto' }}
            />
        </a>
        <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                    <a
                        className="nav-link"
                        href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4860331"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Publications
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link"
                        href="https://www.chatclimate.ai/team"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Team
                    </a>
                </li>
                {auth ? (
                    <li className="nav-item">
                        <a className="nav-link primarybutton" href="/dashboard">
                            Dashboard
                        </a>
                    </li>
                ) : (
                    <>
                        <li className="nav-item">
                            <a className="nav-link secondarybutton" href="/signin">
                                Sign In
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link primarybutton" href="/signup">
                                Sign Up
                            </a>
                        </li>
                    </>
                )}
            </ul>
        </div>
    </div>
</nav>



            {/* Hero Section */}
            <div className="video-background">
                <div className="video-overlay"></div>
                <video autoPlay muted loop className="bg-video">
                    <source src="https://d34p663dzicvaw.cloudfront.net/65ae8ba51dff7302e1a82996_output.mp4" type="video/mp4" />
                </video>
                <div className="hero-content text-center">
                    <h1 className="hero-heading py-5">Ask from Corporates’ Nature-related Disclosures</h1>
                    <p className="hero-subheading">
                        Enhance efficiency in analyzing Corporate Sustainability reports by summarizing content, extracting key data, and synthesizing key findings.
                    </p>
                    <a href="/signup" className="primarybuttonother mx-auto my-4">
                        Sign Up
                    </a>
                </div>
            </div>
            <div className='d-flex footer'>
            <img src={logoImage} className='bottom-right-img' alt='Partnerlogo' />

                <p> © 2024, Department of Finance, University of Zürich. All Rights Reserved.</p></div>


        </div>
    );
};

export default Home;
